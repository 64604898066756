import React from 'react';

/**
 * In development, return the DevToolsProvider.
 * In production, return a component that renders its children.
 */
export default React.lazy(() => (
  __DEV_TOOLS_ENABLED__
    ? import('./Provider')
    : Promise.resolve({ default: ({ children }) => children })
));
