import React from 'react';

/**
 * Return a HOC that will register the dev tool when it is loaded.
 * In production, return an empty HOC.
 *
 * @param {string} devToolName - Dev tool name.
 * @returns {Function} HOC.
 */
export const withDevTool = devToolName => WrappedComponent => (
  !__DEV_TOOLS_ENABLED__
    ? WrappedComponent
    : class WithDevTool extends React.Component {
      state = {
        component: WrappedComponent,
      };

      isUnmounted = false;

      /**
       * @param {...any} args - Component args.
       */
      constructor(...args) {
        super(...args);
        this.loadDevTool();
      }

      /** Mark as no longer mounted to prevent invalid setState. */
      componentWillUnmount() {
        this.isUnmounted = true;
      }

      /**
       * Load the dev tool module.
       */
      async loadDevTool() {
        try {
          const devToolHoc = (await import(`../../../actions/${devToolName}.devtool`)).default;

          if (!this.isUnmounted) {
            this.setState({ component: devToolHoc(WrappedComponent) });
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(`Could not load dev tool ${devToolName}`);
        }
      }

      /** @returns {object} JSX. */
      render() {
        return React.createElement(this.state.component, this.props);
      }
    }
);

const EmptyComponent = () => null;

/**
 * Return a component that will be lazy loaded and register the dev tool module when ready.
 * In production, returns an empty component.
 *
 * @param {string} devToolName - Dev tool name.
 * @returns {Function} React component.
 */
export const getDevTool = devToolName => (
  __DEV_TOOLS_ENABLED__
    ? withDevTool(devToolName)(EmptyComponent)
    : EmptyComponent
);
