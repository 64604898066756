import React, { Suspense, lazy } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

import { Provider as ReduxProvider } from 'react-redux';
import { hide as hideSplashScreen } from 'services/splash';
import store from 'redux/store';
import { HelmetProvider } from 'react-helmet-async';
import DevToolsUi from './generic/devtools/core/ui';
import DevToolsProvider from './generic/devtools/core/context/provider/lazy';
import IntlProvider from './generic/intl/intl-provider/IntlProvider';
import LoadDevTool from './generic/devtools/core/actions/load/LoadDevTool';
import LoadingScreen from './generic/loading/loading-screen/LoadingScreen';
import routes from './routes';
import Header from './generic/header/Header';
import Confirmation from './views/inscription/confirmation/Confirmation';
import Profile from './views/profile/Profile';

// const HomeDev = lazy(() => import('./views/home_dev/HomeDevView'));
const Home = lazy(() => import('./views/home/HomeView'));
const Redirect = lazy(() => import('./views/redirect/redirect'));
const NotFound = lazy(() => import('./views/error/not-found/NotFoundView'));
const Saisine = lazy(() => import('./views/saisine/Saisine'));
const Inscription = lazy(() => import('./views/inscription/Inscription'));
const Callback = lazy(() => import('./views/callback/Callback'));
const Development = lazy(() => import('./views/home_dev/HomeDevView'));
const Sitemap = lazy(() => import('./views/sitemap/Sitemap'));

const enhancer = compose(
  lifecycle({
    /** Hide splash screen when app is mounted. */
    componentDidMount() {
      hideSplashScreen();
    },
  }),
);

const App = () => (
  <HelmetProvider>
    <ReduxProvider store={store}>
      <IntlProvider>
        <Router>
          <Suspense fallback={<LoadingScreen />}>
            <DevToolsProvider>
              <Header />
              <Switch>
                <Route
                  path={routes.HOME.ROOT}
                  exact
                  component={Home}
                />
                <Route
                  path={routes.INSCRIPTION.ROOT}
                  exact
                  component={Inscription}
                />
                <Route
                  path={routes.INSCRIPTION.CONFIRMATION}
                  exact
                  component={Confirmation}
                />
                <Route
                  path={routes.CALLBACK.ROOT}
                  exact
                  component={Callback}
                />
                <Route
                  path={routes.PROFILE.ROOT}
                  exact
                  component={Profile}
                />
                <Route
                  path={routes.CALLBACK_Dev.ROOT}
                  exact
                  component={Redirect}
                />
                <Route
                  path={routes.DEVELOPMENT.ROOT}
                  exact
                  component={Development}
                />
                <Route
                  path={routes.DEVELOPMENT.SAISINE}
                  exact
                  component={Saisine}
                />
                <Route
                  path={routes.SITEMAP.ROOT}
                  exact
                  component={Sitemap}
                />
                <Route component={NotFound} />

              </Switch>

              <DevToolsUi />
              <LoadDevTool name="intl/LanguageSelector" />
            </DevToolsProvider>
          </Suspense>
        </Router>
      </IntlProvider>
    </ReduxProvider>
  </HelmetProvider>
);

export default enhancer(App);
