import {
  createStore,
  applyMiddleware,
  combineReducers,
  compose,
} from 'redux';

import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { responsiveStoreEnhancer } from 'redux-responsive';
import { composeWithDevTools } from 'redux-devtools-extension';

import responsiveReducer from './responsive/reducer';
import intlReducer from './intl/reducer';

// Do not refactor this into !['production', 'test'].includes(process.env.NODE_ENV)
// Because uglify cannot statically analyze this.
const isDevEnvironment = (
  process.env.NODE_ENV !== 'production'
  && process.env.NODE_ENV !== 'test'
);

// Create root reducer.
const reducer = combineReducers({
  responsive: responsiveReducer,
  intl: intlReducer,
});

const middlewares = [
  thunk,
];

if (isDevEnvironment) {
  middlewares.push(createLogger({
    collapsed: true,
    diff: true,
    duration: true,
  }));
}

const composer = isDevEnvironment
  ? composeWithDevTools
  : compose;

const enhancers = composer(
  responsiveStoreEnhancer,
  applyMiddleware(...middlewares),
);

const initialState = {};

export default createStore(reducer, initialState, enhancers);
