import moment from 'moment';

import { storeLocale, setHtmlLangAttribute, getDefaultLocale } from 'services/intl';
import { ACTIONS } from './constants';

export const SET_LOCALE_LOADING = locale => ({
  type: ACTIONS.SET_LOCALE_LOADING,
  payload: {
    locale,
  },
});

export const SET_LOCALE_SUCCESS = ({ locale, messages }) => ({
  type: ACTIONS.SET_LOCALE_SUCCESS,
  payload: {
    locale,
    messages,
  },
});

export const SET_LOCALE_FAILURE = ({ locale, err }) => ({
  type: ACTIONS.SET_LOCALE_FAILURE,
  error: true,
  payload: {
    locale,
    err,
  },
});

export const setLocale = (locale = getDefaultLocale()) => async (dispatch) => {
  dispatch(SET_LOCALE_LOADING(locale));

  try {
    const { default: bundle } = await import(`intl/locales/${locale}`);

    moment.locale(locale);
    setHtmlLangAttribute(locale);
    storeLocale(locale);

    return dispatch(SET_LOCALE_SUCCESS({
      locale,
      ...bundle,
    }));
  } catch (err) {
    return dispatch(SET_LOCALE_FAILURE({
      locale,
      err,
    }));
  }
};
