import React from 'react';
import qs from 'qs';
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import Footer from 'react/generic/footer/Footer';
import config from 'config/env';
import Breadcrumbs from 'react/generic/breadcrumbs/Breadcrumbs';
import { teleprocedureInsee } from 'constant/CardsConstants';

const ACCEPTED = 'Validé';
const REFUSED = 'Refusé';
const PENDING = 'En attente validation';

const Confirmation = (props) => {
  const [status, setStatus] = React.useState(PENDING);
  const [idDemande, setIdDemande] = React.useState(null);
  const [demande, setDemande] = React.useState(true);
  const [errorSiren, setErrorSiren] = React.useState(false);

  React.useEffect(() => {
    const header = qs.parse(window.location.search.slice(window.location.search.indexOf('?') + 1));
    if (header.invalid) {
      setErrorSiren(true);
    } else if (header.idDemande) {
      setIdDemande(header.idDemande);
      const uuid = uuidv4();
      const headers = {
        'Content-Type': 'application/json',
        'X-Correlation-ID': uuid,
      };
      axios.get(`${config.backOfficeUrl}/faux-psp/v1/demande/${header.idDemande}`, {
        headers,
      })
        .then((response) => {
          setDemande(response.data);
          setStatus(response.data.statut);
        })
        .catch(() => {
          setDemande(null);
        });
    }
  }, []);

  if (errorSiren) {
    return (
      <>
        <div className="fr-container fr-my-5w">
          <Breadcrumbs
            currentPageLabel="Confirmation"
            links={[
              { href: '/', label: 'Accueil' },
              { href: '/inscription', label: 'Inscription' },
            ]}
          />
          <div className="fr-grid-row fr-grid-row--center">
            <div className="fr-col fr-col-md-8">
              <p style={{ textAlign: 'center' }}>
                Aucun dirigeant n&apos;ayant déclaré son courriel sur insee.fr, ProConnect
                ne peut soumettre votre demande d&apos;accès.
              </p>
            </div>
          </div>
          <div className="fr-container-fluid">
            <div className="fr-grid-row fr-grid-row--center">
              <div style={{ textAlign: 'center' }} className="fr-col fr-col-md-8">
                <p style={{ fontStyle: 'italic', textAlign: 'initial' }}>
                  Pour bénéficier de l&apos;ensemble des fonctionnalités ProConnect,
                  vous pouvez proposer à votre dirigeant de renseigner son adresse
                  courriel dans le répertoire SIRENE à partir de ce{' '}
                  <a
                    className="link-dse"
                    href={teleprocedureInsee}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    formulaire<span className="fr-fi--sm fr-fi-external-link-line" />
                  </a>.
                </p>
                <button
                  className="fr-btn fr-mr-2w"
                  onClick={() => { props.history.push('/'); }}
                >
                  Poursuivre mon inscription
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer fixed />
      </>
    );
  }

  if (idDemande && demande) {
    return (
      <>
        <div className="fr-container fr-my-5w">
          <Breadcrumbs
            currentPageLabel="Confirmation"
            links={[
              { href: '/', label: 'Accueil' },
              { href: '/inscription', label: 'Inscription' },
            ]}
          />
          <div className="fr-grid-row fr-grid-row--center">
            <div className="fr-col fr-col-md-8">
              <h1 className="fr-h4 text-centered">Votre demande a été envoyée par courriel à vos dirigeants.</h1>
              <p style={{ margin: '0', fontStyle: 'italic' }}>
                Maintenant vous êtes le dirigeant, et afin de poursuivre la démonstration,
                veuillez cliquer sur le
                {' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link-dse"
                  href={`${config.proconnectUrl}/?idDemande=${demande.idDemandeProConnect}`}
                >
                  lien transmis aux dirigeants<span className="fr-fi--sm fr-fi-external-link-line" />
                </a>
                {' '}
                pour vous authentifier
                comme dirigeant et valider
                cette demande d&apos;accès.
              </p>
            </div>
          </div>
          <div className="fr-grid-row fr-grid-row--center">
            <div className="fr-col fr-col-md-8">
              <hr className="fr-my-5w" />
            </div>
          </div>
          <div className="fr-container-fluid">
            <div className="fr-grid-row fr-grid-row--center">
              <div className="fr-col fr-col-md-8">
                <p style={{ fontStyle: 'italic' }}>
                  Vous êtes de nouveau le professionnel à l&apos;origine de la demande.
                  Veuillez recharger la page pour obtenir le résultat de votre
                  demande d&apos;accès.
                </p>
                <button className="fr-btn fr-mr-2w" onClick={() => { window.location.reload(); }}>Recharger la page</button>
                {status === ACCEPTED

                  ? (<><span className="status-answer">Votre accès a été validé.</span></>)
                  : (
                    <>
                      {status === REFUSED

                        ? (<><span className="status-answer">Votre accès a été refusé.</span></>)
                        : (<></>)}
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
        <Footer fixed />
      </>
    );
  }

  return (
    <div className="fr-container fr-my-5w text-centered">
      <h1 className="fr-h3">Aucune demande n&apos;a été trouvé</h1>
      <button className="fr-btn" onClick={() => { props.history.push('/'); }}>Retour à l&apos;accueil</button>
    </div>
  );
};

Confirmation.propTypes = {
  history: PropTypes.object,
};

Confirmation.defaultProps = {
  history: null,
};

export default Confirmation;
