import { addClassname, removeClassname, removeClassnames } from 'services/dom/css';

const splash = document.getElementById('splash');
const body = document.getElementsByTagName('body')[0];

/**
 * Hide splash screen.
 */
export const hide = () => {
  addClassname(splash, 'splash--fading');

  // Give it time to fade away before hiding it.
  setTimeout(
    () => {
      addClassname(splash, 'splash--hidden');
      removeClassname(body, 'body--has-splash');
    },
    500,
  );
};

/**
 * Show splash screen.
 */
export const show = () => {
  addClassname(body, 'body--has-splash');
  removeClassnames(splash, 'splash--fading splash--hidden');
};
