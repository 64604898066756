import React from 'react';

/**
 * In development, return the dev tools ui.
 * In production, return an empty component.
 */
export default React.lazy(() => (
  __DEV_TOOLS_ENABLED__
    ? import('./portal/Portal')
    : Promise.resolve({ default: () => null })
));
