import React from 'react';
import PropTypes from 'prop-types';
import logoPro from 'style/assets/logo/logoPro.svg';

const ButtonProConnect = props => (
  <div className="fr-col-12 fr-col-md-5">
    <button className="fr-btn fr-btn--secondary" onClick={props.onclick}>
      <span style={{ marginRight: '.5rem' }}>{props.text}</span>
      <img src={logoPro} className="fr-responsive-img" style={{ width: '6rem' }} alt="Logo PSP" />
    </button>
    <span className="fr-hint-text fr-mt-1v">{props.moreInfo}</span>
  </div>
);

export default ButtonProConnect;

ButtonProConnect.propTypes = {
  onclick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  moreInfo: PropTypes.string.isRequired,
};

ButtonProConnect.defaultProps = {

};
