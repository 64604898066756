import React from 'react';
import ReactDOM from 'react-dom';

import App from 'react/App';
import { setLocale } from 'redux/intl/actions';
import store from 'redux/store';

import './style/main.scss';
import '../node_modules/@gouvfr/dsfr/dist/css/dsfr.min.css';

(async function main() {
  await store.dispatch(setLocale());

  ReactDOM.render(
    <App />,
    document.getElementById('app'),
  );
}());
