import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const Header = () => {
  const theme = useTheme();
  const history = useHistory();
  const smDown = useMediaQuery(theme.breakpoints.down(767));
  const mdDown = useMediaQuery(theme.breakpoints.down(991));

  if (smDown) {
    return (
      <header role="banner" className="fr-header">
        <div className="fr-header__body">
          <div className="fr-container">
            <div className="fr-header__body-row">
              <div className="fr-header__brand">
                <div className="fr-header__brand-top">
                  <div className="fr-header__logo">
                    <p className="fr-logo">
                      République
                      <br />Française
                    </p>
                  </div>
                </div>
                <div className="fr-header__service">
                  <a href="/" title="Accueil - [À MODIFIER | Nom du site / service]">
                    <p className="fr-header__service-title">Portail de démonstration</p>
                  </a>
                </div>
              </div>
            </div>
            {window.location.pathname === '/development' || window.location.pathname === '/development/saisine'
              ? (
                <></>)
              : (
                <>
                  <div
                    className="fr-header__tools"
                    style={{
                      width: '1rem', position: 'absolute', top: '0', right: '1rem', transform: 'translateY(1rem)',
                    }}
                  >
                    <div className="fr-header__tools-links" style={{ display: 'initial' }}>
                      <ul className="fr-links-group" style={{ margin: '0' }}>
                        <li>
                          <button
                            className="fr-btn fr-btn--secondary fr-fi-lock-line fr-btn--icon-left"
                            onClick={() => history.push('/profile')}
                          >
                            Mon espace professionnel
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
        {window.location.pathname === '/development' || window.location.pathname === '/development/saisine'
          ? (
            <>
              <div className="fr-header__menu fr-modal" id="modal-870" aria-labelledby="button-871">
                <div className="fr-container">
                  <button className="fr-link--close fr-link" aria-controls="modal-870">Fermer</button>
                  <div className="fr-header__menu-links" />
                  <nav className="fr-nav" id="header-navigation" role="navigation" aria-label="Menu principal">
                    <ul className="fr-nav__list">
                      <li className="fr-nav__item">
                        <NavLink className="fr-nav__link" to="/development" target="_self" exact>Accueil</NavLink>
                      </li>
                      <li className="fr-nav__item">
                        <NavLink className="fr-nav__link" to="/development/saisine" target="_self" exact>Saisine</NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          )
          : (<></>)}

      </header>
    );
  }

  if (mdDown) {
    return (
      <header role="banner" className="fr-header">
        <div className="fr-header__body">
          <div className="fr-container">
            <div className="fr-header__body-row">
              <div className="fr-header__brand fr-enlarge-link">
                <div className="fr-header__brand-top">
                  <div className="fr-header__logo">
                    <p className="fr-logo">
                      République
                      <br />Française
                    </p>
                  </div>
                </div>
                <div className="fr-header__service">
                  <a href="/" title="Accueil - Portail de présentation">
                    <p className="fr-header__service-title">Portail de démonstration</p>
                  </a>
                </div>
              </div>
            </div>
            {window.location.pathname === '/development' || window.location.pathname === '/development/saisine'
              ? (
                <></>)
              : (
                <>
                  <div
                    className="fr-header__tools"
                    style={{
                      position: 'absolute', top: '0', right: '1rem', transform: 'translateY(50%)',
                    }}
                  >
                    <div className="fr-header__tools-links" style={{ display: 'initial' }}>
                      <ul className="fr-links-group" style={{ margin: '0' }}>
                        <li>
                          <button
                            className="fr-btn fr-btn--secondary fr-fi-lock-line fr-btn--icon-left"
                            onClick={() => history.push('/profile')}
                          >
                            Mon espace professionnel
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
        {window.location.pathname === '/development' || window.location.pathname === '/development/saisine'
          ? (
            <>
              <div className="fr-header__menu fr-modal" id="modal-870" aria-labelledby="button-871">
                <div className="fr-container">
                  <button className="fr-link--close fr-link" aria-controls="modal-870">Fermer</button>
                  <div className="fr-header__menu-links" />
                  <nav className="fr-nav" id="header-navigation" role="navigation" aria-label="Menu principal">
                    <ul className="fr-nav__list">
                      <li className="fr-nav__item">
                        <NavLink className="fr-nav__link" to="/development" target="_self" exact>Accueil</NavLink>
                      </li>
                      <li className="fr-nav__item">
                        <NavLink className="fr-nav__link" to="/development/saisine" target="_self" exact>Saisine</NavLink>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          )
          : (<></>)}

      </header>
    );
  }

  return (
    <header role="banner" className="fr-header">
      <div className="fr-header__body">
        <div className="fr-container">
          <div className="fr-header__body-row">
            <div className="fr-header__brand fr-enlarge-link">
              <div className="fr-header__brand-top">
                <div className="fr-header__logo">
                  <p className="fr-logo">
                    République
                    <br />Française
                  </p>
                </div>
              </div>
              <div className="fr-header__service">
                <a href="/" title="Accueil - [À MODIFIER | Nom du site / service]">
                  <p className="fr-header__service-title">Portail de démonstration</p>
                </a>
              </div>
            </div>
            {window.location.pathname === '/development' || window.location.pathname === '/development/saisine'
              ? (
                <></>)
              : (
                <>

                  <div className="fr-header__tools">
                    <div className="fr-header__tools-links" style={{ display: 'initial' }}>
                      <ul className="fr-links-group" style={{ margin: '0' }}>
                        <li>
                          <button
                            className="fr-btn fr-btn--secondary fr-fi-lock-line fr-btn--icon-left"
                            onClick={() => history.push('/profile')}
                          >
                            Mon espace professionnel
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )}
          </div>

        </div>
      </div>
      {window.location.pathname === '/development' || window.location.pathname === '/development/saisine'
        ? (
          <>
            <div className="fr-header__menu fr-modal" id="modal-870" aria-labelledby="button-871">
              <div className="fr-container">
                <button className="fr-link--close fr-link" aria-controls="modal-870">Fermer</button>
                <div className="fr-header__menu-links" />
                <nav className="fr-nav" id="header-navigation" role="navigation" aria-label="Menu principal">
                  <ul className="fr-nav__list">
                    <li className="fr-nav__item">
                      <NavLink className="fr-nav__link" to="/development" target="_self" exact>Accueil</NavLink>
                    </li>
                    <li className="fr-nav__item">
                      <NavLink className="fr-nav__link" to="/development/saisine" target="_self" exact>Saisine</NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </>
        )
        : (<></>)}

    </header>
  );
};

export default Header;
