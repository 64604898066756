/**
 * Toutes les constantes qui sont affichées dans les cards.
 */
export const cardsLabel = {
  BIENVENUE: 'Bienvenue dans ProConnect',
  TEXTE_1: "Suite à votre demande d'inscription sur REPLACE_ME, ProConnect vous accompagne en 3 étapes pour prouver votre lien avec cette entreprise. Pour cela, ProConnect fait appel au service FranceConnect dans le but de vérifier votre identité.",
  BOUTON_AUTHENT: "S'authentifier avec FranceConnect",
  LIEN_FC: "Qu'est-ce que FranceConnect",
  INFOS_SAISIES: 'Informations saisies sur REPLACE_ME',
};

export const teleprocedureInsee = 'https://habilitateur.sirene.insee.fr';
