import { connect } from 'react-redux';

import { getEmptyObject } from 'utils/object';

/**
 * A proxy for react-redux's `connect` that does not expose `dispatch`
 * if `mapDispatchToProps` is not specified.
 *
 * @param {Function} [mapStateToProps] - Map state to props.
 * @param {Function} [mapDispatchToProps] - Map dispatch to props.
 * @param {Function} [mergeProps] - Merge props.
 * @returns {Function} HOC.
 */
export default (
  mapStateToProps,
  mapDispatchToProps = getEmptyObject,
  mergeProps,
) => connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
);
