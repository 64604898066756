import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { getDevTool } from './index';

export default class LoadDevTool extends PureComponent {
  component = getDevTool(this.props.name);

  static propTypes = {
    name: PropTypes.string.isRequired,
  };

  /** @returns {object} JSX. */
  render() {
    const {
      name,
      ...props
    } = this.props;

    return React.createElement(
      this.component,
      props,
    );
  }
}
