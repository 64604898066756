import { createSelector } from 'reselect';

export const selectIntl = state => state.intl;

export const selectLocale = state => selectIntl(state).locale;
export const selectMessages = state => selectIntl(state).messages;

export const isSetLocaleLoading = state => !!selectIntl(state).isLoading.setLocale;

export const selectLocaleBundle = createSelector(
  [
    selectLocale,
    selectMessages,
  ],
  (locale, messages) => ({
    locale,
    messages,
  }),
);
