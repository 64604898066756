import { transform } from 'lodash';

import sassConfig from 'style/config.variables.scss';
import theme from 'style/theme.variables.scss';

/** @type {object.<string, number>} Breakpoint name -> Breakpoint max size in px. */
export const BREAKPOINTS_MAP = transform(
  sassConfig.breakpoints,
  (breakpoints, bpValue, bpName) => {
    breakpoints[bpName] = parseInt(bpValue, 10);
  },
);

/** @type {object<string, string>} Variable name (camel-cased) -> value as string. */
export const THEME = theme;
