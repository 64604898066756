import { combineReducers } from 'redux';

import { ACTIONS } from './constants';

export default combineReducers({
  locale: (state = '', action) => {
    switch (action.type) {
      case ACTIONS.SET_LOCALE_SUCCESS:
        return action.payload.locale;
      default:
        return state;
    }
  },

  messages: (state = {}, action) => {
    switch (action.type) {
      case ACTIONS.SET_LOCALE_SUCCESS:
        return action.payload.messages;
      default:
        return state;
    }
  },

  isLoading: combineReducers({
    setLocale: (state = 0, action) => {
      switch (action.type) {
        case ACTIONS.SET_LOCALE_LOADING:
          return state + 1;
        case ACTIONS.SET_LOCALE_SUCCESS:
        case ACTIONS.SET_LOCALE_FAILURE:
          return state - 1;
        default:
          return state;
      }
    },
  }),
});
