/**
 * String helper pour transformer les strings.
 * String.prototype est read-only.
 */

/**
 * Génère une chaine de caractère alphanumérique de taille length.
 *
 * @param {number} length - La taille de la chaine de caractère à générer.
 * @returns {undefined}
 */
function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/**
 * Formate le siren.
 *
 * @param {*} siren - Le siren à formater.
 */
function rewriteSiren(siren) {
  let newSiren;
  if (typeof siren === 'number') {
    newSiren = siren.toString();
  } else {
    newSiren = siren;
  }
  const siren1 = newSiren.slice(0, 3);
  const siren2 = newSiren.slice(3, 6);
  const siren3 = newSiren.slice(6, 9);
  return `${siren1}\u00A0${siren2}\u00A0${siren3}`;
}

const sanitizeNumber = string => string.split('').filter(e => e.match(/[0-9]/)).join('');

const deserializeSiren = (siren) => {
  if (siren.split(' ').length === 0) { return siren; }
  return siren.split(' ').filter(e => e !== ' ').join('');
};

const dynamicSirenFormat = (siren) => {
  let newSiren;
  if (typeof siren === 'number') {
    newSiren = siren.toString();
  } else {
    newSiren = siren;
  }
  if (newSiren.length <= 3) { return newSiren; }
  if (newSiren.length <= 6) { return `${newSiren.slice(0, 3)}\u00A0${newSiren.slice(3, 6)}`; }
  return `${newSiren.slice(0, 3)}\u00A0${newSiren.slice(3, 6)}\u00A0${newSiren.slice(6, 9)}`;
};

const isValidSiren = (siren) => {
  if (typeof siren === 'number') {
    return siren.toString().split('').length === 9;
  }
  if (typeof siren !== 'string') {
    return false;
  }
  return siren.split('').length === 9;
};

const stringUtils = {
  generateRandomString,
  sanitizeNumber,
  deserializeSiren,
  rewriteSiren,
  dynamicSirenFormat,
  isValidSiren,
};

export default stringUtils;
