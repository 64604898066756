import React from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import logoCertif from 'style/assets/logo/logo_certif.png';
import Breadcrumbs from 'react/generic/breadcrumbs/Breadcrumbs';
import ButtonProConnect from 'react/generic/button/ButtonProConnect';
import Footer from 'react/generic/footer/Footer';
import config from 'config/env';
import { useHistory } from 'react-router';
import './profile.scss';
import stringUtils from 'utils/StringUtils';

const voidPersona = {
  id: '', siren: '', lastname: '', firstname: '', mail: '', label: '',
};

const personaArray = [
  {
    id: 0,
    siren: 712054923,
    lastname: 'DUBIGNOT',
    firstname: 'Jean Philippe Arnaud',
    mail: '',
    label: 'Jean Philippe DUBIGNOT - Dirigeant pour le SIREN 712 054 923',
  },
  {
    id: 1,
    siren: 171402704,
    lastname: 'LIT',
    firstname: 'Philippe',
    mail: '',
    label: 'Philippe LIT - Dirigeant pour le SIREN 171 402 704',
  },
  {
    id: 2,
    siren: 794027219,
    lastname: 'MARTIN',
    firstname: 'Vincent',
    mail: '',
    label: 'Vincent MARTIN - Dirigeant pour le SIREN 794 027 219',
  },
  {
    id: 3,
    siren: 712054923,
    lastname: 'DUBOIS',
    firstname: 'Angela Claire Louise',
    mail: '',
    label: 'Angela Claire Louise DUBOIS - Non dirigeante pour le SIREN 712 054 923',
  },
  {
    id: 4,
    siren: 171402704,
    lastname: 'BERGERE',
    firstname: 'Jerome Pierre Eric',
    mail: '',
    label: 'Jerome Pierre Eric BERGERE - Non dirigeant pour le SIREN 171 402 704',
  },
  {
    id: 5,
    siren: 356000000,
    lastname: 'DELALALANDE',
    firstname: 'Emmanuel Alexandre Nicolas',
    mail: '',
    label: 'Emmanuel Alexandre Nicolas DELALALANDE - Non dirigeant pour le SIREN 356 000 000',
  },
];

const Profile = () => {
  const [persona, setPersona] = React.useState(null);
  const [idDemande, setIdDemande] = React.useState(null);
  const [demande, setDemande] = React.useState(null);
  const [globalError, setGlobalError] = React.useState(null);

  const history = useHistory();

  const handleChange = (e) => {
    e.preventDefault();
    setGlobalError(null);
    const id = Number.parseInt(e.target.value, 10);
    const el = personaArray.find(item => item.id === id);
    if (el) setPersona(el);
    else setPersona(voidPersona);
  };

  const urlLienValidationProConnect = (data) => {
    if (data.urlDemandeProConnect) {
      return data.urlDemandeProConnect.toString();
    }
    return `${config.proconnectUrl}?idDemande=${data.idDemandeProConnect}`;
  };

  const sendInfo = (e) => {
    e.preventDefault();
    const endPoint = `${config.backOfficeUrl}/faux-psp/v1/demande`;

    const raw = JSON.stringify({
      nom: persona.lastname,
      prenom: persona.firstname,
      mail: persona.mail,
      siren: persona.siren,
      representantLegal: true,
      contexte: 'CERTIFICATION',
    });

    const uuid = uuidv4();

    const headers = {
      'Content-Type': 'application/json',
      'X-Correlation-ID': uuid,
    };

    axios.put(`${endPoint}`, raw, {
      headers,
    })
      .then((response) => {
        if (response.data.idDemandeProConnect) {
          window.location = urlLienValidationProConnect(response.data);
        } else {
          throw new Error('Erreur lors de la création de la demande');
        }
      })
      .catch(() => { setGlobalError('Service indisponible, veuillez réessayer plus tard.'); });
    return true;
  };

  React.useEffect(() => {
    if (history.location.state?.demande) {
      const demandeState = history.location.state.demande;
      setIdDemande(history.location.state.idDemande);
      setDemande(demandeState);
    }
  }, []);

  // Retour depuis une demande caractérisé par un id et validé
  if (idDemande) {
    // Demande validée : on affiche l'icone de certification
    return (
      <>
        <div className="fr-container fr-my-5w">
          <h1 className="fr-h2 text-centered">Mon espace professionnel</h1>
          <p className="fr-h4 text-centered">Vous êtes bien dirigeant pour&nbsp;le&nbsp;SIREN n°&nbsp;{stringUtils.rewriteSiren(demande?.siren)}.</p>
          <div id="form-persona">
            {
              !persona
                ? (
                  <>
                    <div className="fr-container-fluid fr-mt-2w">
                      <div className="fr-grid-row fr-grid-row--gutters">
                        <div className="fr-col-12 fr-col-md-6">
                          <div className="fr-input-group">
                            <label className="fr-label" htmlFor="text-input-text-identifiant">
                              SIREN
                              <input
                                className="fr-input fr-mb-1w fr-mt-1v"
                                type="number"
                                id="text-input-text-identifiant"
                                name="text-input-text-identifiant"
                                value={stringUtils.rewriteSiren(demande?.siren)}
                              />
                            </label>
                          </div>

                          <div className="fr-input-group">
                            <label className="fr-label" htmlFor="lastname">Nom
                              <input
                                className="fr-input fr-mb-3w fr-mt-1v"
                                type="text"
                                id="lastname"
                                name="lastname"
                                value={demande?.nomDirigeant}
                              />
                            </label>
                            <div className="fr-input-group">
                              <label className="fr-label" htmlFor="firstname">Prénom
                                <input
                                  className="fr-input fr-mb-3w fr-mt-1v"
                                  type="text"
                                  id="firstname"
                                  name="firstname"
                                  value={demande?.prenomDirigeant}
                                />
                              </label>
                            </div>
                          </div>

                          <div className="fr-input-group">
                            <label className="fr-label" htmlFor="firstname">Courriel
                              <input
                                className="fr-input fr-mb-3w fr-mt-1v"
                                type="text"
                                id="mail"
                                name="mail"
                                value={demande?.mailDirigeant}
                              />
                            </label>
                          </div>
                        </div>
                        <div
                          className="fr-col-12 fr-col-md-6"
                          style={{
                            display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
                          }}
                        >
                          <div className="vignette-certification">
                            <img src={logoCertif} className="fr-responsive-img" style={{ width: '8rem' }} alt="Logo PSP" />
                          </div>
                          <p className="fr-col-9" style={{ textAlign: 'center', flex: 'initial' }}>
                            Votre compte a été certifié avec ProConnect,
                            vous pouvez maintenant saisir votre demande de subvention.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="fr-container-fluid fr-mt-4w" style={{ textAlign: 'center' }}>
                      <button className="fr-btn" onClick={() => { history.push('/'); }}>Retour à l&apos;accueil</button>
                    </div>
                  </>
                )
                : (<></>)
            }
          </div>

        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <div className="fr-container fr-my-5w">
        <Breadcrumbs
          currentPageLabel="Mon espace personnel"
          links={[
            { href: '/', label: 'Accueil' },
          ]}
        />
        <h1 className="fr-h2">Mon espace professionnel</h1>
        <p className="fr-text fr-mb-3v" style={{ fontStyle: 'italic', textAlign: 'initial' }}>Veuillez sélectionner un persona pour jouer ce cas de démonstration.</p>
        { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="fr-label" htmlFor="select">
          Choix de persona
        </label>
        <select defaultValue="a" className="fr-select fr-col-4" id="select" name="select" onChange={(e) => { handleChange(e); }}>
          <option value="a" disabled>Selectionnez un persona</option>
          {personaArray.map(item => (<option key={item.id} value={item.id}>{item.label}</option>))}
        </select>
        <div id="form-persona">
          {
            persona
              ? (
                <>
                  <div className="fr-container-fluid fr-mt-2w">
                    <div className="fr-grid-row fr-grid-row--gutters">
                      <div className="fr-col-12 fr-col-md-6">
                        <div className="fr-input-group">
                          <label className="fr-label" htmlFor="text-input-text-identifiant">
                            SIREN
                            <input
                              className="fr-input fr-mb-1w fr-mt-1v"
                              type="text"
                              id="text-input-text-identifiant"
                              name="text-input-text-identifiant"
                              value={stringUtils.rewriteSiren(persona.siren)}
                            />
                          </label>
                        </div>

                        <div className="fr-input-group">
                          <label className="fr-label" htmlFor="lastname">Nom
                            <input
                              className="fr-input fr-mb-3w fr-mt-1v"
                              type="text"
                              id="lastname"
                              name="lastname"
                              value={persona.lastname}
                            />
                          </label>
                          <div className="fr-input-group">
                            <label className="fr-label" htmlFor="firstname">Prénom
                              <input
                                className="fr-input fr-mb-3w fr-mt-1v"
                                type="text"
                                id="firstname"
                                name="firstname"
                                value={persona.firstname}
                              />
                            </label>
                          </div>
                        </div>

                        <div className="fr-input-group">
                          <label className="fr-label" htmlFor="firstname">Courriel
                            <input
                              className="fr-input fr-mb-3w fr-mt-1v"
                              type="text"
                              id="mail"
                              name="mail"
                              value={persona.mail}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="fr-container-fluid fr-mb-2w" style={{ display: 'flex' }}>
                      {globalError
                        ? (
                          <>
                            <p id="global-desc-error" className="fr-error-text">
                              {globalError}
                            </p>
                          </>
                        )
                        : (<></>)}
                    </div>
                    <ButtonProConnect
                      text="Je prouve que je suis dirigeant avec"
                      moreInfo="J&apos;utilise ProConnect pour certifier que je suis dirigeant et bénéficier de droits élargis"
                      onclick={sendInfo}
                    />
                  </div>
                </>
              )
              : (<></>)
          }
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Profile;
