const LOCALE_STORAGE_KEY = 'locale';

/**
 * Looks for the presence of the locale in the storage.
 *
 * @returns {string|undefined} - The locale if found, undefined otherwise.
 */
export const getStoredLocale = () => localStorage.getItem(LOCALE_STORAGE_KEY);

/**
 * Stores locale in the storage.
 *
 * @param {string} locale - The locale to store.
 */
export const storeLocale = (locale) => {
  localStorage.setItem(LOCALE_STORAGE_KEY, locale);
};
