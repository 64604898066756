import React, { memo } from 'react';

import ImpulseSpinner from '../impulse-spinner/ImpulseSpinner';

import classNames from './loading-screen.module.scss';

// Feel free to edit the loading screen.
const LoadingScreen = () => (
  <div className={classNames.container}>
    <ImpulseSpinner
      overrideClassName={classNames.spinner}
      overrideClassNames={classNames}
    />
  </div>
);

LoadingScreen.displayName = 'LoadingScreen';

export default memo(LoadingScreen);
