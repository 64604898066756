import React from 'react';
import PropTypes from 'prop-types';
import logoPro from 'style/assets/logo/logoPro.svg';
import env from 'config/env';

const Footer = props => (
  <footer
    className="fr-footer fr-mt-9w"
    role="contentinfo"
    id="footer"
    style={{
      backgroundColor: 'white',
      position: props.fixed ? 'fixed' : 'relative',
      bottom: '0',
    }}
  >
    <div className="fr-container" style={{ display: 'flex' }}>
      <div className="fr-container-fluid fr-mr-2w" style={{ display: 'flex', alignItems: 'center' }}>
        <img className="fr-footer__logo" src={logoPro} alt="Logo ProConnect" style={{ width: '100px' }} />
      </div>
      <div className="fr-footer__bottom" style={{ marginTop: '0' }}>
        <ul className="fr-footer__bottom-list" style={{ padding: '0' }}>
          <li className="fr-footer__bottom-item">
            <a className="fr-footer__bottom-link" href="/sitemap">Plan du site</a>
          </li>
          <li className="fr-footer__bottom-item">
            <a className="fr-footer__bottom-link" href="/development">Accueil (développeur)</a>
          </li>
          <li className="fr-footer__bottom-item">
            <a className="fr-footer__bottom-link" href="/">Accessibilité: non conforme</a>
          </li>
          <li className="fr-footer__bottom-item">
            <a className="fr-footer__bottom-link" target="_blank" rel="noreferrer noopener" href={`${env.proconnectUrl}/mentions-legales`}>Mentions légales</a>
          </li>
          <li className="fr-footer__bottom-item">
            <a className="fr-footer__bottom-link" target="_blank" rel="noreferrer noopener" href={`${env.proconnectUrl}/cgu`}>CGU</a>
          </li>
          <li className="fr-footer__bottom-item">
            <a className="fr-footer__bottom-link" target="_blank" rel="noreferrer noopener" href={`${env.proconnectUrl}/donnees-personnelles`}>Données personnelles</a>
          </li>
          <li className="fr-footer__bottom-item">
            <a className="fr-footer__bottom-link" target="_blank" rel="noreferrer noopener" href={`${env.proconnectUrl}/gestion-cookies`}>Gestion des cookies</a>
          </li>
        </ul>
        <div className="fr-footer__bottom-copy">
          <p>Sauf mention contraire, tous les textes de ce site sont sous{' '}
            <a className="link-dse" href="https://github.com/etalab/licence-ouverte/blob/master/LO.md" target="_blank" rel="noopener noreferrer">licence etalab-2.0</a>
          </p>
        </div>
      </div>
    </div>
    {env.dateVersion !== null && env.dateVersion !== ''
      ? (
        <div className="fr-container" style={{ color: 'var(--g600)', fontSize: '0.75rem' }}>
          Version: {env.app.version}-{env.dateVersion}
        </div>
      ) : <></>}
  </footer>

);

Footer.propTypes = {
  fixed: PropTypes.bool,
};

Footer.defaultProps = {
  fixed: false,
};

export default Footer;
