import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const Breadcrumbs = (props) => {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down(767));
  if (smDown) {
    return (
      <nav role="navigation" className="fr-breadcrumb" aria-label="vous êtes ici :">
        <div id="breadcrumb-1">
          <ol className="fr-breadcrumb__list">
            {props.links.map(item => (
              <li key={item.label}>
                <a className="fr-breadcrumb__link link-dse" href={item.href} target="_self">{item.label}</a>
              </li>
            ))}
            <li key={props.currentPageLabel}>
              <span className="fr-breadcrumb__link" aria-current="page">{props.currentPageLabel}</span>
            </li>
          </ol>
        </div>
      </nav>
    );
  }

  return (
    <nav role="navigation" className="fr-breadcrumb" aria-label="vous êtes ici :">
      <button className="fr-breadcrumb__button" aria-expanded="false" aria-controls="breadcrumb-1">Voir le fil d’Ariane</button>
      <div className="fr-collapse" id="breadcrumb-1">
        <ol className="fr-breadcrumb__list">
          {props.links.map(item => (
            <li key={item.label}>
              <a className="fr-breadcrumb__link link-dse" href={item.href} target="_self">{item.label}</a>
            </li>
          ))}
          <li key={props.currentPageLabel}>
            <span className="fr-breadcrumb__link" aria-current="page">{props.currentPageLabel}</span>
          </li>
        </ol>
      </div>
    </nav>
  );
};
Breadcrumbs.propTypes = {
  currentPageLabel: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape(
    {
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    },
  )).isRequired,
};

Breadcrumbs.defaultProps = {

};

export default Breadcrumbs;
