export default {
  HOME: {
    ROOT: '/',
  },
  INSCRIPTION: {
    ROOT: '/inscription',
    CONFIRMATION: '/inscription/confirmation',
  },
  CALLBACK: {
    ROOT: '/callback',
  },
  PROFILE: {
    ROOT: '/profile',
  },
  CALLBACK_Dev: {
    ROOT: '/redirect',
  },
  DEVELOPMENT: {
    ROOT: '/development',
    SAISINE: '/development/saisine',
  },
  SITEMAP: {
    ROOT: '/sitemap',
  },
};
